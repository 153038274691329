import {defineStore} from "pinia";

export const useCentralStore = defineStore('central_store', {
    state: () => (
        {
            _token:''
        }
    ),
    getters: {
        getToken: (state) => {
            return state._token;
        }
    },

    actions: {
        login(_token) {
            this._token = _token;
        },

        logout() {
            this._token = null;
        },
    },
    persist:{
        storage:persistedState.cookies
    },
})